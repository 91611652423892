<template>
  <v-app-bar flat color="white">
    <img
        height="60"
        :src="logoUrl"
        alt="Logo"
    />

    <v-spacer v-if="!isOnline"/>
    <v-icon v-if="!isOnline" color="error">mdi-wifi-remove</v-icon>
    <span class="ml-2 error--text" v-if="!isOnline">Kein Internet</span>

    <v-spacer />

    <v-btn class="" @click="goToLink(homeSiteLink)"
           color="primary">
      Zurück zur Startseite
    </v-btn>
  </v-app-bar>
</template>

<script>
import headerInfo from "@/indivconfig/headerInfo.json";
import { useOnline } from '@vueuse/core'

export default {
  methods: {
    goToLink(link) {
      window.location.href = link;
    },
  },
  name: 'PB-Header',
  data: () => ({
    logoUrl: headerInfo.logoUrl,
    phoneNumber: headerInfo.phoneNumber,
    homeSiteLink: headerInfo.url,
  }),

  computed: {
    isOnline(){
      return useOnline()?.value
    }
  }
}
</script>

<style scoped>
</style>
